<template>
  <nuxt-link
    v-if="link && link.to"
    :class="classes"
    :to="link.to"
    data-testid="element_000162"
    v-bind="bind"
    @click.prevent="onClick"
  >
    <slot />
  </nuxt-link>
  <div v-else :class="classes" data-testid="element_000162">
    <slot/>
  </div>
</template>

<script lang="ts" setup>
import { type ILink } from '@/shared/types/ui/link'

interface Props {
  link: ILink,
}

const props = defineProps<Props>()

const { link } = toRefs(props)

const nuxtApp = useNuxtApp()
const router = useRouter()

const bind = computed(() => {
  if (link.value.title) {
    return {
      title: link.value.title,
    }
  }
  return {}
})

const classes = computed(() => {
  return [
    'link',
    link.value?.disabled ? 'disabled' : '',
  ]
})

const checkIfExternal = (to: ILink['to']): boolean => {
  if (typeof to !== 'string') {
    return false
  }

  const [scheme, host] = nuxtApp.$config.public.BASE_URL.split('://')
  return (to.startsWith('http') || to.startsWith('//')) && !to.includes(host)
}

const onClick = () => {
  if (link.value?.disabled) {
    return false
  }

  const to = link.value?.to || '/'
  if (checkIfExternal(to) || link.value.inNewTab) {
    window.open(to as string, '_blank')
    return false
  }

  const resolve = router.resolve(to)
  if (resolve.fullPath !== router.currentRoute.value.fullPath) {
    router.push(link.value.to)
  }

  return false
}
</script>
